'use strict';
import {streamLobbyStreamDetector} from './stream.lobby.stream.detector';

const templateStreamLobbyItemVideoCover = document.getElementById(
    'stream-lobby-item-video-cover');
const parentWrapperClass = 'streamLobbyItemVideo';

const getPreviewLink = (videoId) => `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;
const YOUTUBE_REGEX = new RegExp('^(https?://)?(www.youtube.com)/.+$');
const YOUTUBE_TINNY_REGEX = new RegExp('^(https?://)?(youtu.?be)/.+$');

const getVideoPreview = (url, fallbackImage) => {
  if (url.match(YOUTUBE_REGEX)) {
    const videoId = url.split('').slice(32, 43).join('');
    return getPreviewLink(videoId)
  } else if (url.match(YOUTUBE_TINNY_REGEX)) {
    const arrayParsedUrl = url.split('/');
    const videoId = arrayParsedUrl?.[arrayParsedUrl.length - 1];
    return getPreviewLink(videoId);
  }

  return fallbackImage;
}

export function streamLobbyVideo(container, getHighlightOrStream, thumbnailPreview) {
  let cloneTemplateStreamLobbyItemVideoCover = templateStreamLobbyItemVideoCover.content.cloneNode(
      true);

  container.querySelector('.' + parentWrapperClass).
      append(cloneTemplateStreamLobbyItemVideoCover);

  const imageNode = container
    .querySelector('.' + parentWrapperClass)
    .querySelector('.streamLobbyItemVideoCoverImage');

  const previewImage = getVideoPreview(getHighlightOrStream, thumbnailPreview);

  const downloadingImage = new Image();
  downloadingImage.src = previewImage;
  downloadingImage.onload = function(r) {
    const previewWidth = r.target.naturalWidth;
    if (previewWidth <= 120) {
      imageNode.src = thumbnailPreview;
    } else {
      imageNode.src = downloadingImage.src;
    }
  };
  downloadingImage.onerror = function () {
    imageNode.src = thumbnailPreview;
  }

  container.querySelector('.streamLobbyItemVideoCover').
      addEventListener('click', function(event) {
        let parent;

        if (event.target.parentElement.classList.value === parentWrapperClass) {
          parent = event.target.parentElement;
          parent.removeChild(event.target);
        } else if (event.target.parentElement.parentElement.classList.value ===
            parentWrapperClass) {
          parent = event.target.parentElement.parentElement;
          parent.removeChild(event.target.parentElement);
        } else if (event.target.parentElement.parentElement.parentElement.classList.value ===
            parentWrapperClass) {
          parent = event.target.parentElement.parentElement.parentElement;
          parent.removeChild(event.target.parentElement.parentElement);
        }

        if (parent) {
          const iframe = document.createElement('iframe');

          iframe.src = streamLobbyStreamDetector(getHighlightOrStream);
          iframe.title = 'Lobby stream';
          iframe.frameBorder = '0';
          iframe.allowFullScreen = true;
          iframe.scrolling = 'no';
          iframe.height = '100%';
          iframe.width = '100%';
          iframe.allow = 'autoplay';

          parent.append(iframe);
        }
      });
}
