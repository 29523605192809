import {httpRequest} from './http.request';
import {getCookie, getCurrentActiveGameId, getRedirectUrl, isLf2Page, setAccessToken, setRefreshToken} from './helpers';
import { streamLobbyVideo } from "./stream.lobby.video";
import videoFallback from '../img-main-page/videoThumbnailWz.png';
import {API_URL, pwpAccess, pwpRefresh, CLIENT_MAIN_PAGE} from "./constants";
import {sendAmplitudeButtonEvent, sendAmplitudeOpenPopup} from "./amplitude";
import {streamLobbyUrl} from "./http.urls";

const URL_BUILDER = (url) => `${API_URL}${url}`;

const GET_FORM_SETTINGS_URL = "/auth/buyer/get-form-settings";
const SIGN_IN_URL = "/auth/buyer/sign-in";
const SIGN_UP_URL = "/auth/buyer/game-landing-sign-up";
const LF2_SIGN_UP_URL = "/auth/buyer/lf-2-sign-up";
const RESET_PASSWORD_URL = "/auth/buyer/reset-password";
const CHECK_RESET_PASSWORD_URL = "/auth/buyer/check-reset-password-available";

const body = document.body;
const modalTriggers = document.querySelectorAll(".popup-trigger");
export const bodyBlackout = document.querySelector(".body-blackout");
const closeButtons = document.querySelectorAll(".popup-modal__close");
const closeVideoPopup = document.querySelector('.close-video-popup')

const signInModal = document.querySelector(`[data-popup-modal="log-in"]`);
export const signUpModal = document.querySelector(`[data-popup-modal="sign-up"]`);
const loginEmailModal = document.querySelector(`[data-popup-modal="log-in-email"]`);
const signUpEmailModal = document.querySelector(`[data-popup-modal="sign-up-email"]`);
export const videoProModal = document.querySelector(`[data-popup-modal="pro-stream-modal"]`);
const passwordRecoveryModal = document.querySelector(`[data-popup-modal="password-recovery-modal"]`);
const proStreamModal = document.querySelector(`[data-popup-modal="pro-stream-modal"]`);

const loginEmailBtn = document.querySelector(".log-in-email-trigger");
const signUpEmailBtn = document.querySelector(".sign-up-email-trigger");
const landingSignUpEmailBtns = document.querySelectorAll(".landing-sign-up-button");
const signInTriggers = document.querySelectorAll(".sign-in-trigger");
const signUpTriggers = document.querySelectorAll(".sign-up-trigger");
const passwordRecoveryTriggers = document.querySelectorAll(".password-recovery-trigger");
const discordRedirectTriggers = document.querySelectorAll(".discord-redirect-btn");
const googleRedirectTriggers = document.querySelectorAll(".google-redirect-btn");
const passwordVisibilityButton = document.querySelector(".password-visibility-toggle");

const signUpEmailInput = document.querySelector(".sign-up-mail-input");
const signUpEmailInputLabel = document.querySelector(".email-sign-up-label");

const emailInput = document.querySelector(".email-input");
const emailInputLabel = document.querySelector(".email-label");

const passwordInput = document.querySelector(".password-input");
const passwordInputLabel = document.querySelector(".password-label");

const resetPasswordInput = document.querySelector(".reset-password-input");
const resetPasswordLabel = document.querySelector(".reset-password-label");

const directSignUpInputs = document.querySelectorAll('.direct-sign-up-input');

const submitButtonLoginByEmail = document.querySelector(".log-in-form-button");
const submitButtonSignUpByEmail = document.querySelector(".sign-up-form-button");
const resetPasswordButton = document.querySelector(".reset-password-btn");
const resetPasswordLoginButton = document.querySelector(".recover-pass-login");
const openProStreamButtons = document.querySelectorAll(".pro-stream-trigger");

const updateIntercom = () => {

  if (window?.Intercom) {
    window.Intercom('update', {"paid": false});
  } else {
    console.warn('---NO INTERCOM INSTANCE---');
  }
};

export const openModal = (modal) => {
  body.classList.add("scroll-block");
  modal.classList.add("is--visible");
  bodyBlackout.classList.add("is-blacked-out");
}

const clearFormErrors = () => {
  emailInput.classList.remove("error-input");
  emailInput.classList.remove("error-warning");
  emailInputLabel.innerHTML = '';
  emailInputLabel.classList.remove("error-tips");
  passwordInput.classList.remove("error-input");
  passwordInputLabel.innerHTML = '';
  passwordInputLabel.classList.remove("error-tips");
  signUpEmailInput.classList.remove("error-input");
  signUpEmailInputLabel.classList.remove("error-tips");
  resetPasswordInput.classList.remove("error-input");
  resetPasswordLabel.classList.remove("error-tips");
  passwordVisibilityButton.classList.remove('password-error');
  resetPasswordLabel.innerHTML = '';
  resetPasswordLabel.classList.remove("basic-tips");
  resetPasswordInput.classList.remove("input-with-label");
};

const clearForm = () => {
  emailInput.value = "";
  passwordInput.value = "";
  signUpEmailInput.value = "";
  resetPasswordInput.value = "";
};

fetch(URL_BUILDER(GET_FORM_SETTINGS_URL))
  .then((res) => {
    res.json().then((data) => {
      const providerList = data.data.oauth_provider_list;
      const googleLink = providerList.find((item) => item.name === "Google").url;
      const discordLink = providerList.find((item) => item.name === "Discord").url;

      googleRedirectTriggers.forEach((item) => {
        item.addEventListener("click", (e) => {
          sendAmplitudeButtonEvent('Google auth redirect button');
          e.preventDefault();
          window.location.href = googleLink
        });
      });

      discordRedirectTriggers.forEach((item) => {
        item.addEventListener("click", (e) => {
          sendAmplitudeButtonEvent('Discord auth redirect button');
          e.preventDefault();
          window.location.href = discordLink;
        });
      });
    });
  })
  .catch((e) => {
    console.log(e);
  });

const closeAllModals = (e) => {
  e.preventDefault();
  body.classList.remove("scroll-block");
  signInModal.classList.remove("is--visible");
  signUpModal.classList.remove("is--visible");
  bodyBlackout.classList.remove("is-blacked-out");
  loginEmailModal.classList.remove("is--visible");
  signUpEmailModal.classList.remove("is--visible");
  passwordRecoveryModal.classList.remove("is--visible");
  proStreamModal.classList.remove("is--visible");
  clearFormErrors();
  clearForm();
};

[...closeButtons, closeVideoPopup].forEach((btn) => {
  btn.addEventListener("click", (e) => {
    sendAmplitudeButtonEvent('Close popup button');
    closeAllModals(e)
  });
});

bodyBlackout.addEventListener("click", (e) => closeAllModals(e));

signUpTriggers.forEach((signUpTrigger) => {
  signUpTrigger.addEventListener("click", (e) => {
    sendAmplitudeButtonEvent('Sign up button');
    e.preventDefault();
    signInModal.classList.remove("is--visible");
    loginEmailModal.classList.remove("is--visible");
    signUpModal.classList.add("is--visible");
  });
});

signInTriggers.forEach((signUpTrigger) => {
  signUpTrigger.addEventListener("click", (e) => {
    sendAmplitudeButtonEvent('Sign in button');
    e.preventDefault();
    clearForm();
    clearFormErrors();
    passwordRecoveryModal.classList.remove("is--visible");
    signUpEmailModal.classList.remove("is--visible");
    signUpModal.classList.remove("is--visible");
    signInModal.classList.add("is--visible");
  });
});

passwordRecoveryTriggers.forEach((signUpTrigger) => {
  signUpTrigger.addEventListener("click", (e) => {
    sendAmplitudeButtonEvent('Password recovery button');
    e.preventDefault();
    signUpEmailModal.classList.remove("is--visible");
    loginEmailModal.classList.remove("is--visible");
    signInModal.classList.remove("is--visible");
    signUpModal.classList.remove("is--visible");
    passwordRecoveryModal.classList.add("is--visible");
  });
});

loginEmailBtn.addEventListener("click", () => {
  loginEmailModal.classList.add("is--visible");
  signInModal.classList.remove("is--visible");
});

signUpEmailBtn.addEventListener("click", () => {
  signUpEmailModal.classList.add("is--visible");
  signUpModal.classList.remove("is--visible");
});

function openProStream(e) {
  e.preventDefault();
  const proCard = e.target.parentNode;
  sendAmplitudeButtonEvent('Pro card');
  sendAmplitudeOpenPopup('Pro popup');
  const videoUrl = proCard.getAttribute('data-popup-stream');

  // init video wrapper
  const videoContainer = document.querySelector('.popup-stream-content');
  const videoContainerWrapper = document.createElement('div');
  videoContainerWrapper.classList.add('streamLobbyItemVideo');

  // clean previous data
  videoContainer.replaceChildren(videoContainerWrapper);

  streamLobbyVideo(videoContainer, videoUrl, videoFallback);
}

passwordVisibilityButton.addEventListener("click", (e) => {
  e.preventDefault();

  if (passwordInput?.type === 'password') {
    passwordInput.type = 'text';
    passwordVisibilityButton.classList.add('password-visible')
  } else if (passwordInput?.type === 'text') {
    passwordInput.type = 'password';
    passwordVisibilityButton.classList.remove('password-visible')
  }
});

const redirectAfterLogin = () => {
  try {
    const autoJoinPremade = JSON.parse(window.localStorage.getItem('autoJoinPremade'));

    if (autoJoinPremade) {
      window.location.href = getRedirectUrl(streamLobbyUrl(autoJoinPremade));
      return;
    }
    window.location.href = getRedirectUrl(CLIENT_MAIN_PAGE);
  } catch (e) {
    console.log(e);
    window.location.href = getRedirectUrl(CLIENT_MAIN_PAGE);
  }
}

const logInHandler = async (req) => {
  const email = emailInput.value;
  const password = passwordInput.value;

  submitButtonLoginByEmail.classList.add("button--loading");

  clearFormErrors();

  req(URL_BUILDER(SIGN_IN_URL), {
    email,
    password,
  })
    .then((res) => {
      const refreshToken = res.data[pwpRefresh];
      const accessToken = res.data[pwpAccess];
      setRefreshToken(refreshToken);
      setAccessToken(accessToken);
      redirectAfterLogin();
    })
    .catch((error) => {
      error.json().then((parsedErr) => {
        const errors = parsedErr.data.errors;

        if (parsedErr.message === "Incorrect email or password.") {
          emailInput.classList.add("error-input");
          emailInputLabel.classList.add("error-tips");
          emailInputLabel.innerHTML = parsedErr.message;
          passwordInput.classList.add("error-input");
          passwordInputLabel.classList.add("error-tips");
          passwordInputLabel.innerHTML = parsedErr.message;
          passwordVisibilityButton.classList.add('password-error');
          submitButtonLoginByEmail.classList.remove("button--loading");
          return;
        }
        if (Object.keys(errors).includes("email")) {
          emailInput.classList.add("error-input");
          emailInputLabel.classList.add("error-tips");
          emailInputLabel.innerHTML = errors.email[0];
        }
        if (Object.keys(errors).includes("password")) {
          passwordInput.classList.add("error-input");
          passwordInputLabel.classList.add("error-tips");
          passwordInputLabel.innerHTML = errors.password[0];
          passwordVisibilityButton.classList.add('password-error');
        }
        submitButtonLoginByEmail.classList.remove("button--loading");
      });
    });
};

submitButtonLoginByEmail.addEventListener("click", () => {
  logInHandler(httpRequest('post'));
});

const signUpFn = async (inputValue) => {
  submitButtonSignUpByEmail.classList.add("button--loading");

  const activeGameId = getCurrentActiveGameId();

  const signUpHandler = async (req) => {
    clearFormErrors();
    const url = isLf2Page() ? LF2_SIGN_UP_URL : SIGN_UP_URL;
    req(URL_BUILDER(url), {
      email: inputValue,
      _ga_cid: getCookie('_ga_cid'),
      game: activeGameId,
    })
      .then((res) => {
        updateIntercom();
        const refreshToken = res.data[pwpRefresh];
        const accessToken = res.data[pwpAccess];
        setRefreshToken(refreshToken);
        setAccessToken(accessToken);
        redirectAfterLogin();
      })
      .catch((error) => {
        error.json().then((parsedErr) => {
          const errors = parsedErr.data.errors;

          if (Object.keys(errors).includes("email")) {
            signUpEmailInput.classList.add("error-input");
            signUpEmailInputLabel.classList.add("error-tips");
            signUpEmailInputLabel.innerHTML = errors.email[0];
          }
          submitButtonSignUpByEmail.classList.remove("button--loading");
        });
      });
  };

  signUpHandler(httpRequest('post'));
}

submitButtonSignUpByEmail.addEventListener("click", (e) => {
  const emailSignUp = signUpEmailInput.value;
  signUpFn(emailSignUp)
});

resetPasswordButton.addEventListener("click", (e) => {
  const emailReset = resetPasswordInput.value;
  resetFn(emailReset);
});

resetPasswordInput.onkeydown = (e) => {
  if (e.keyCode === 13) {
    e.preventDefault();
    resetFn(e.target.value);
  }
}

const resetFn = async (emailForResetPassword) => {
  const resetPassword = async (req) => {
    resetPasswordButton.classList.add("button--loading");
    req(URL_BUILDER(CHECK_RESET_PASSWORD_URL), {
      email: emailForResetPassword,
    })
        .then((res) => {
          if (res.data.reset_password_available) {
            req(URL_BUILDER(RESET_PASSWORD_URL), {
              email: emailForResetPassword,
            })
                .then((response) => {
                  if (response.data.result) {
                    resetPasswordButton.classList.remove("button--loading");
                    resetPasswordLoginButton.disabled = false;
                  }
                })
                .catch((e) => {
                  e.json().then((parsedErr) => {
                    console.log(parsedErr?.message);
                  });
                });
          }

          if (res.data.next_reset_password_interval_seconds > 0) {
            resetPasswordInput.classList.add("error-input");
            resetPasswordLabel.classList.add("error-tips");
            resetPasswordButton.disabled = true;
            resetPasswordLoginButton.disabled = true;

            let seconds = res.data.next_reset_password_interval_seconds;
            resetPasswordLabel.innerHTML = `You can change your password after ${seconds} seconds`;

            const timeToResetPass = setInterval(() => {
              seconds = seconds - 1;
              resetPasswordLabel.innerHTML = `You can change your password after ${seconds} seconds`;
              if (seconds <= 0) {
                resetPasswordLabel.innerHTML = 'Email';
                resetPasswordInput.classList.remove("error-input");
                resetPasswordLabel.classList.remove("error-tips");
                resetPasswordButton.disabled = false;
                resetPasswordLoginButton.disabled = false;
                clearTimeout(timeToResetPass);
              }
            }, 1000);

            resetPasswordButton.classList.remove("button--loading");
          }
        })
        .catch((error) => {
          error.json().then((parsedErr) => {
            const errors = parsedErr.data.errors;

            resetPasswordInput.classList.remove("input-with-label");
            resetPasswordLabel.classList.remove("basic-tips");

            resetPasswordInput.classList.add("error-input");
            resetPasswordLabel.classList.add("error-tips");

            if (parsedErr.message === "Incorrect email.") {
              resetPasswordLabel.innerHTML = parsedErr.message;
            } else {
              resetPasswordLabel.innerHTML = errors.email[0];
            }

            resetPasswordButton.classList.remove("button--loading");
          });
        });
  };
  resetPassword(httpRequest('post'));
}



signUpEmailInput.addEventListener("input", (e) => {
  e.preventDefault();
  signUpEmailInput.innerHTML = '';
  signUpEmailInput.classList.remove("error-input");
  signUpEmailInputLabel.classList.remove("error-tips");
});

signUpEmailInput.onkeydown = (e) => {
  if (e.keyCode === 13) {
    e.preventDefault();
    signUpFn(e.target.value);
  }
}

emailInput.addEventListener("input", (e) => {
  e.preventDefault();
  emailInputLabel.innerHTML = '';
  emailInput.classList.remove("error-input");
  emailInputLabel.classList.remove("error-tips");
});

emailInput.onkeydown = (e) => {
  if (e.keyCode === 13) {
    e.preventDefault();
    logInHandler(httpRequest('post'));
  }
}

passwordInput.addEventListener("input", (e) => {
  e.preventDefault();
  passwordInputLabel.innerHTML = '';
  passwordInput.classList.remove("error-input");
  passwordInputLabel.classList.remove("error-tips");
  passwordVisibilityButton.classList.remove('password-error');
});

passwordInput.onkeydown = (e) => {
  if (e.keyCode === 13) {
    e.preventDefault();
    logInHandler(httpRequest('post'));
  }
}

resetPasswordInput.addEventListener("input", (e) => {
  e.preventDefault();

  resetPasswordInput.classList.remove("error-input");
  resetPasswordLabel.classList.remove("error-tips");

  if (resetPasswordInput.value !== '') {
    resetPasswordLabel.innerHTML = 'Email';
    resetPasswordLabel.classList.add("basic-tips");
    resetPasswordInput.classList.add("input-with-label");
  } else {
    resetPasswordLabel.innerHTML = '';
    resetPasswordLabel.classList.remove("basic-tips");
    resetPasswordInput.classList.remove("input-with-label");
  }
});

resetPasswordLoginButton.addEventListener('click', (e) => {
  e.preventDefault();

  emailInput.value = resetPasswordInput.value;
  passwordRecoveryModal.classList.remove("is--visible");
  loginEmailModal.classList.add("is--visible");
  resetPasswordInput.value = '';
})

modalTriggers.forEach((trigger) => {
  trigger.addEventListener("click", (e) => {
    e.preventDefault();
    const type = trigger.getAttribute("data-popup-trigger");
    const popupModal = document.querySelector(`[data-popup-modal="${type}"]`);

    openModal(popupModal);
  });
});

window.addEventListener('load', () => {
  openProStreamButtons.forEach((openPro) => {
    openPro.addEventListener('click', openProStream);
  })
});

const directSignUpFn = async (req, inputValue, index) => {
  const directSignUpBtn = landingSignUpEmailBtns?.[index];
  directSignUpBtn?.querySelector('.spinner').classList.remove('hide');
  directSignUpBtn?.querySelector('.button-text').classList.add('hide');

  const activeGameId = getCurrentActiveGameId();
  const url = isLf2Page() ? LF2_SIGN_UP_URL : SIGN_UP_URL;

  req(URL_BUILDER(url), {
    email: inputValue,
    _ga_cid: getCookie('_ga_cid'),
    game: activeGameId,
  }).
  then((res) => {
    const refreshToken = res.data[pwpRefresh];
    const accessToken = res.data[pwpAccess];
    setRefreshToken(refreshToken);
    setAccessToken(accessToken);
    redirectAfterLogin();
  }).catch((e) => {
    e.json().then((parsedErr) => {
      const errors = parsedErr.data.errors;
      const errorMessageBlock = document.querySelectorAll('.direct-sign-up-error')?.[index];

      if (errors.hasOwnProperty('email')) {
        errorMessageBlock.innerText = errors?.email?.[0];
        return;
      }
      if (parsedErr?.message?.toLowerCase()?.includes('already exists')) {
        clearForm();
        clearFormErrors();
        loginEmailModal.classList.add("is--visible");
        loginEmailModal.querySelector('.email-input').value = inputValue;

        directSignUpInputs.forEach((inputField) => {
          inputField.value = '';
        });

        emailInputLabel.innerHTML = 'Email is already registered';
        emailInputLabel.classList.add("error-tips");
        loginEmailModal.querySelector('.email-input').classList.add("error-warning");

        return;
      }
      if (parsedErr?.message) {
        errorMessageBlock.innerText = parsedErr.message;
      }
    });

    directSignUpBtn?.querySelector('.spinner').classList.add('hide');
    directSignUpBtn?.querySelector('.button-text').classList.remove('hide');
  })
}

directSignUpInputs
  .forEach((inputField, index) => {
    inputField.addEventListener('input', (e) => {
      const errorMessage = document.querySelectorAll('.direct-sign-up-error')?.[index];
      errorMessage.innerText = '';
    })

    inputField.onkeydown = (e) => {
      if (e.keyCode === 13) {
        e.preventDefault();
        directSignUpFn(httpRequest('post'), e.target.value, index);
      }
    }
})

landingSignUpEmailBtns.forEach((btn, index) => {
  btn.addEventListener('click', (e) => {
    sendAmplitudeButtonEvent('Direct sign up button');

    btn.querySelector('.spinner').classList.remove('hide');
    btn.querySelector('.button-text').classList.add('hide');

    const fields = document.querySelectorAll('.direct-sign-up-input');
    const inputValue = fields[index].value.trim();

    if (!!inputValue) {
      try {
        const activeGameId = getCurrentActiveGameId();

        if (activeGameId) {
          directSignUpFn(httpRequest('post'), inputValue, index);
        }
      } catch (e) {
        btn.querySelector('.spinner').classList.add('hide');
        btn.querySelector('.button-text').classList.remove('hide');
        console.log(e)
      }
    } else {
      btn.querySelector('.spinner').classList.add('hide');
      btn.querySelector('.button-text').classList.remove('hide');
    }
  })
});
