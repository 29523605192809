'use strict';

export function httpRequest(type) {
  let controller;

  const resetController = () => {
    if (controller) {
      controller.abort();
      controller = null;
    }

    controller = new AbortController();
  }

  if (type === 'post') {
    return async function (url, data) {
      resetController();

      let formData = new FormData();

      Object.keys(data).forEach((key) => {
        formData.append(key, data[key]);
      })

      const response = await fetch(url, {
        method: 'POST',
        signal: controller.signal,
        body: formData,
      });

      if (!response.ok) {
        return Promise.reject(response);
      }

      return response.json();
    }
  }

  return async function (url) {
    if (controller) {
      controller.abort();
      controller = null;
    }

    controller = new AbortController();

    const response = await fetch(url, {
      signal: controller.signal,
    });

    if (!response.ok) {
      return Promise.reject(response);
    }

    return response.json();
  }
}
