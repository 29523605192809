export function streamLobbyStreamDetector(link) {
  const TWITCH_REGEX = new RegExp('^https:\\/\\/(www.)?twitch.tv\\/(\\w+)?(\\/(\\d+))?');
  const YOUTUBE_REGEX = new RegExp('^(https?://)?(www.youtube.com|youtu.?be)/.+$');
  const FACEBOOK_REGEX = new RegExp(
    '(?:https?://)?(?:www.|web.|m.)?(facebook|fb).(com|watch)/(?:video.php\\?v=\\d+|(\\S+)|photo.php\\?v=\\d+|\\?v=\\d+)|\\S+/videos/((\\S+)\\/(\\d+)|(\\d+))/?',
  );

  const currentDomain = window.location.hostname;

  const twitchFormat = /[^/]*$/;

  const cutString = link.match(/[^/]*$/);

  function formatYoutubeUrl(url) {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = url.match(regExp);
    return (match && match[7].length === 11) ? match[7] : false;
  }

  function formatVideoPreviewUrl(url) {
    const testUrl = formatYoutubeUrl(url);

    if (testUrl) {
      return `https://youtube.com/embed/${testUrl}?autoplay=1&mute=1`;
    }
    return '';
  }

  if (link.match(TWITCH_REGEX)) {
    if (link.match('/clip/')) {
      const slicedArrayFromUrl = link.split('/');
      const lastEl = slicedArrayFromUrl[slicedArrayFromUrl?.length - 1];

      if (lastEl) {
        const endOfVideoId = lastEl.indexOf('?');
        if (endOfVideoId !== -1) {
          return `https://clips.twitch.tv/embed?clip=${lastEl.slice(0, endOfVideoId)}&parent=${currentDomain}`;
        }
        return `https://clips.twitch.tv/embed?clip=${lastEl}&parent=${currentDomain}`;
      }

    }

    if (link.match(twitchFormat)) {
      return `https://player.twitch.tv/?channel=${cutString}&parent=${currentDomain}&autoplay=1`;
    }
  }

  if (link.match(FACEBOOK_REGEX)) {
    return `https://www.facebook.com/plugins/video.php?showText=false&href=${link}&autoplay=true`;
  }

  if (link.match(YOUTUBE_REGEX)) {
    return formatVideoPreviewUrl(link);
  }

  return link;
}
